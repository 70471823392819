import React from 'react'
import styled from 'styled-components'

//components
import Layout from '../components/Layout'
import SEO from '../components/SEO'


const Politic = ({ path }) => {
    return (
        <Layout path={path}>
            <SEO
                title="Polityka Prywatności"
                description="Właścicielem strony internetowej art7.pl oraz
            administratorem danych osobowych jest studio art7 sp. z o. o."
            />
            <StyledPolitic>
                <StyledH1>Polityka Prywatności</StyledH1>
                <StyledH2>I Informacje ogólne</StyledH2>
                <ul>
                    <StyledLi>
                        Niniejsza Polityka Prywatności określa sposób pozyskiwania,
                        przetwarzania oraz zabezpieczania danych osobowych w rozumieniu ustawy
                        o ochronie danych osobowych z dnia 29 sierpnia 1997 roku (Dz.U. Nr
                        133, poz. 883 z póź. zm.) oraz ustawą o świadczeniu usług drogą
                        elektroniczną z dnia 18 lipca 2002 r. (Dz.U. Nr 144, poz. 1204 z póź.
                        zm.)
                    </StyledLi>
                    <StyledLi>
                        Właścicielem strony internetowej art7.pl oraz
                        administratorem danych osobowych jest studio art7 sp. z o. o.
                    </StyledLi>
                </ul>

                <StyledH2>II Dane osobowe</StyledH2>
                <ul>
                    <StyledLi>
                        Serwis zbiera informacje podane dobrowolnie przez użytkownika.
                    </StyledLi>
                    <StyledLi>
                        Zawartość strony internetowej można przeglądać bez podawania
                        jakichkolwiek danych osobowych.
                    </StyledLi>
                    <StyledLi>
                        Każda osoba, która udostępniła swoje dane osobowe ma prawo do dostępu
                        do ich treści oraz możliwość ich poprawiania, uaktualniania,
                        uzupełniania, jak i również żądania zaprzestania przetwarzania danych
                        osobowych oraz wniesienia sprzeciwu wobec przetwarzania danych
                        osobowych. Wymienione czynności można dokonać poprzez wysłanie
                        stosownego oświadczenia na adres email: biuro@art7.pl
                    </StyledLi>
                    <StyledLi>
                        Pozyskane przez administratora dane osobowe są przechowywane,
                        przetwarzane i chronione zgodnie z obowiązującymi przepisami prawa.
                        Zbiór danych osobowych został zgłoszony do Urzędu Ochrony Danych
                        Osobowych (uodo.gov.pl)
                    </StyledLi>
                </ul>

                <StyledH2>III Informacja o plikach cookies</StyledH2>
                <ul>
                    <StyledLi>
                        Serwis korzysta z plików cookies.
                    </StyledLi>
                    <StyledLi>
                        Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności pliki tekstowe,
                        które
                        przechowywane są w urządzeniu końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze
                        stron
                        internetowych Serwisu. Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą,
                        czas
                        przechowywania ich na urządzeniu końcowym oraz unikalny numer.
                    </StyledLi>
                    <StyledLi>
                        Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu pliki cookies oraz
                        uzyskującym do nich
                        dostęp jest operator Serwisu.
                    </StyledLi>
                    <StyledLi>
                        Pliki cookies wykorzystywane są w następujących celach:
                    </StyledLi>
                    <StyledLi>
                        tworzenia statystyk, które pomagają zrozumieć, w jaki sposób Użytkownicy Serwisu korzystają ze
                        stron
                        internetowych, co umożliwia ulepszanie ich struktury i zawartości;
                    </StyledLi>
                    <StyledLi>
                        utrzymania sesji Użytkownika Serwisu (po zalogowaniu), dzięki której Użytkownik nie musi na
                        każdej podstronie
                        Serwisu ponownie wpisywać loginu i hasła;
                    </StyledLi>
                    <StyledLi>
                        określania profilu użytkownika w celu wyświetlania mu dopasowanych materiałów w sieciach
                        reklamowych, w
                        szczególności sieci Google.
                    </StyledLi>
                    <StyledLi>
                        W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies: „sesyjne” (session cookies)
                        oraz „stałe”
                        (persistent cookies). Cookies „sesyjne” są plikami tymczasowymi, które przechowywane są w
                        urządzeniu końcowym
                        Użytkownika do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia oprogramowania
                        (przeglądarki
                        internetowej). „Stałe” pliki cookies przechowywane są w urządzeniu końcowym Użytkownika przez
                        czas określony w
                        parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika.
                    </StyledLi>
                    <StyledLi>
                        Oprogramowanie do przeglądania stron internetowych (przeglądarka internetowa) zazwyczaj
                        domyślnie dopuszcza
                        przechowywanie plików cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą
                        dokonać zmiany
                        ustawień w tym zakresie. Przeglądarka internetowa umożliwia usunięcie plików cookies. Możliwe
                        jest także
                        automatyczne blokowanie plików cookies Szczegółowe informacje na ten temat zawiera pomoc lub
                        dokumentacja
                        przeglądarki internetowej.
                    </StyledLi>
                    <StyledLi>
                        Ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności dostępne na
                        stronach
                        internetowych Serwisu.
                    </StyledLi>
                    <StyledLi>
                        Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Serwisu i wykorzystywane mogą być
                        również przez
                        współpracujących z operatorem Serwisu reklamodawców oraz partnerów.
                    </StyledLi>
                    <StyledLi>
                        Zalecamy przeczytanie polityki ochrony prywatności tych firm, aby poznać zasady korzystania z
                        plików cookies
                        wykorzystywane w statystykach: Polityka ochrony prywatności Google Analytics.
                    </StyledLi>
                    <StyledLi>
                        Pliki cookies mogą być wykorzystane przez sieci reklamowe, w szczególności sieć Google, do
                        wyświetlenia reklam
                        dopasowanych do sposobu, w jaki użytkownik korzysta z Serwisu. W tym celu mogą zachować
                        informację o ścieżce
                        nawigacji użytkownika lub czasie pozostawania na danej stronie.
                    </StyledLi>
                    <StyledLi>
                        W zakresie informacji o preferencjach użytkownika gromadzonych przez sieć reklamową Google
                        użytkownik może
                        przeglądać i edytować informacje wynikające z plików cookies przy pomocy narzędzia:
                        https://www.google.com/ads/preferences/
                    </StyledLi>
                </ul>

                <StyledH2>IV Zarządzanie plikami cookies – jak w praktyce wyrażać i cofać zgodę?</StyledH2>
                <ul>
                    <StyledLi>
                        Jeśli użytkownik nie chce otrzymywać plików cookies, może zmienić ustawienia przeglądarki.
                        Zastrzegamy, że
                        wyłączenie obsługi plików cookies niezbędnych dla procesów uwierzytelniania, bezpieczeństwa,
                        utrzymania
                        preferencji użytkownika może utrudnić, a w skrajnych przypadkach może uniemożliwić korzystanie
                        ze stron www.
                    </StyledLi>
                    <StyledLi>
                        W celu zarządzania ustawieniami cookies wybierz z listy poniżej przeglądarkę internetową/ system
                        i postępuj
                        zgodnie z instrukcjami
                    </StyledLi>
                    <StyledLi>
                        – Internet Explorer
                    </StyledLi>
                    <StyledLi>
                        – Chrome
                    </StyledLi>
                    <StyledLi>
                        – Safari
                    </StyledLi>
                    <StyledLi>
                        – Firefox
                    </StyledLi>
                    <StyledLi>
                        – Opera
                    </StyledLi>
                    <StyledLi>
                        – Android
                    </StyledLi>
                    <StyledLi>
                        – Safari (iOS)
                    </StyledLi>
                    <StyledLi>
                        – Windows Phone
                    </StyledLi>
                </ul>


                <StyledH2>V Udostępnianie danych</StyledH2>
                <ul>
                    <StyledLi>
                        Dane podlegają udostępnieniu podmiotom zewnętrznym wyłącznie w granicach prawnie dozwolonych.
                    </StyledLi>
                    <StyledLi>
                        Operator może mieć obowiązek udzielania informacji zebranych przez Serwis upoważnionym organom
                        na podstawie
                        zgodnych z prawem żądań w zakresie wynikającym z żądania.
                    </StyledLi>
                </ul>

                <StyledH2>VI Postanowienia końcowe</StyledH2>
                <ul>
                    <StyledLi>
                        Administrator ma prawo do zmian w niniejszej Polityce Prywatności. Osoby udostępniające swoje
                        dane osobowe
                        obowiązuje aktualnie obowiązująca wersja Polityki Prywatności, dostępna na stronie
                        art7.pl
                    </StyledLi>
                    <StyledLi>
                        W sprawach nieuregulowanych niniejszą Polityką Prywatności stosuje się obowiązujące przepisy
                        prawa polskiego.
                    </StyledLi>
                </ul>
            </StyledPolitic>
        </Layout>
    )
}

const StyledPolitic = styled.section`
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 8rem 0;
    width: 40%;
    margin: auto;
    ${({ theme }) => theme.media.phone} {
        margin-top: 5vh;
        width: 80vw;
    }
`
const StyledH1 = styled.h1`
    text-align: center;
    font-weight: 500;
    font-size: 3.5rem;
    margin-bottom: 4rem;
`

const StyledH2 = styled.h2`
    margin: 4rem 0rem;
    font-size: 3rem;
`

const StyledLi = styled.li`
    list-style: disc;
    font-size: 2.2rem;
    margin: 2rem 0rem;
`

export default Politic
